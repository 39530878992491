import React, { useState, useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'
import axios from 'axios'
import {Container, OverlayTrigger, Dropdown, Button, Row, Col, Tab, Tabs, Popover, Card, Alert,Spinner} from 'react-bootstrap'
import {
    FacebookShareButton, FacebookIcon, TwitterShareButton,
    TwitterIcon, LinkedinShareButton, LinkedinIcon,
} from 'react-share'
import JobDescriptionModal from '../../common/modals/JobDescriptionModal/JobDescriptionModal'
import isEmpty from 'lodash.isempty'
import feather from 'feather-icons'
import RefinementPage from '../RefinementPage/RefinementPage'
import styles from './styles/JobDetailPage.module.scss'
import EditIcon from '../../../../assets/images/icons/pencil-icon.svg'
import TrashIcon from '../../../../assets/images/icons/trash-icon.svg'
import PlusIcon from '../../../../assets/images/icons/plus-icon.svg'
import UploadIcon from '../../../../assets/images/icons/upload-icon.svg'
import ShareIcon from '../../../../assets/images/icons/share-icon.svg'
import MultiEmailIcon from '../../../../assets/images/icons/multi-email.png'
import './styles/JobDetailPage.scss'
import 'react-datepicker/dist/react-datepicker.css'
import 'toasted-notes/src/styles.css'
import CandidateManager from '../../common/CandidateManager/CandidateManager'
import BulkCandidateUpload from '../../common/BulkCandidateUpload/BulkCandidateUpload'
import Modal from 'react-bootstrap/Modal'
import Badge from "react-bootstrap/Badge";
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import ReferralModal from '../../common/modals/JobDescriptionModal/ReferralModal'
import JobDeleteConfirmationModal from './JobDeleteConfirmationModal.js'
import { skillIsNotEmpty } from '../../../utils'
import JobExtendConfirmationModal from './JobExtendConfirmationModal.js'
import moment from 'moment'
import EmailSequence from '../../common/EmailSequence/EmailSequence.js'
import JobModal from '../../common/JobModal/JobModal'
import JobWishlist from '../../common/JobModal/JobWishlist'
import JobNotes from '../../common/JobModal/JobNotes'
import InMailModal from '../../common/JobModal/InMailModal'
import InterviewQuestionModal  from '../../common/JobModal/InterviewQuestionModal'
import SweetAlert from 'react-bootstrap-sweetalert'
import { isNull } from 'lodash'

const LEADS = 'lead'
const APPLICANTS = 'applicant'
const AI_MATCHED = 'ai_matched'
const SUBMITTED = 'submitted'
const FIRST_INTERVIEWS = 'first_interview'
const SECOND_INTERVIEWS = 'second_interview'
const ON_HOLD = 'onhold'
const OFFERS = 'offer'
const ARCHIVED = 'reject'
const RECRUITOR_SCREEN = 'recruitor_screen'
const ACTIVE_CANDIDATES = 'active_candidates'
const HIRED = 'hired'

const popover = (shareUrl) => {
    const content = (
        <Popover id="popover-basic">
            <Popover.Content>
                <FacebookShareButton
                    openShareDialogOnClick={false}
                    onClick={(_, url) => {
                        window.open(url, '_blank')
                    }}
                    style={{ marginLeft: 10 }}
                    url={shareUrl}
                    target="_blank"
                >
                    <FacebookIcon className={styles.shareIcon} round />
                </FacebookShareButton>

                <TwitterShareButton
                    openShareDialogOnClick={false}
                    onClick={(_, url) => {
                        window.open(url, '_blank')
                    }}
                    style={{ marginLeft: 10 }}
                    url={shareUrl}
                    target="_blank"
                >
                    <TwitterIcon className={styles.shareIcon} round />
                </TwitterShareButton>

                <LinkedinShareButton
                    openShareDialogOnClick={false}
                    onClick={(_, url) => {
                        window.open(url, '_blank')
                    }}
                    style={{ marginLeft: 10 }}
                    url={shareUrl}
                    target="_blank"
                >
                    <LinkedinIcon className={styles.shareIcon} round />
                </LinkedinShareButton>
            </Popover.Content>
        </Popover>
    )

    return content
}

function JobDetailPage({
  ATSBaseUrl,
  CSVSampleURL,
  jobModel,
  jobNote,
  currentUser,
  currentOrganization,
  isEmailConfigured,
  showInviteFriends,
  publicUrl,
  jobStatus,
  memberOrganization,
  job_stage_count,
  ctRecruitersList,
  job_owner,
  members,
  job_groups,
  emailSequenceExist,
  showCandidateModal = true,
  job_match_count,
  is_show_applicant
})
{
  // Transform job and candidate models to be display-ready
  const Stage = localStorage.getItem('Stage')
  const is_source_job = localStorage.getItem('job_source_id')
  const [job, setJob] = useState(transformJobModel(jobModel))
  const [showJobDescriptionModal, setShowJobDescriptionModal] = useState(false)
  const [showReferralModal, setShowReferralModal] = useState(showInviteFriends)
  const [openEmailSequenceModal, setOpenEmailSequenceModal] = useState(false)
  const [openInMailModal, setOpenInMailModal] = useState(false)
  const [openInterviewQuestionModal, setOpenInterviewQuestionModal] = useState(false)
  const [showRefinmentPage, setShowRefinementpage] = useState(false)
  const [activeTab, setActiveTab] = useState((is_show_applicant == false && (Stage == 'applicant' || Stage == 'active_candidates')) ? 'lead' : ((currentUser.agency_id == 0) ?  Stage || 'lead' : 'applicant'))
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false)
  const [showUploadCandidatePanel, setShowUploadCandidatePanel] = useState(false)
  const [jobDelete, setJobDelete] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [errorCreatingSubmission, setErrorCreatingSubmission] = useState(null)
  const [alertApplyForJob, setAlertApplyForJob] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadData, setLoadData] = useState(false)
  const userBelongsToCT = currentOrganization?.name === window.ch_const.ct_org_name
  const showGradingUI = userBelongsToCT
  const [openExtendModal, setOpenExtendModal] = useState(false)
  const expiryDate = moment(jobModel?.expiry_date).format('YYYY-MM-DD')
  const beforeExpiryDate = moment(jobModel?.expiry_date).subtract(7, 'day').format('YYYY-MM-DD')
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const [jobExtend, setJobExtend] = useState(false)
  const [value, setValue] = useState()
  const [isReadMore, setIsReadMore] = useState(true);
  const [isReadMoreNotes, setIsReadMoreNotes] = useState(true);
  const [showdisplaycount, setShowdisplaycount] = useState(false)
  const [isEditJob, setIsEditJob] = useState(false)
  const [addWishList, setAddWishList] = useState((is_source_job == job.id && jobModel.creator_id == currentUser.id) ? true :false)
  const [addNoteList, setAddNoteList] = useState(false)
  const [disableCloseButton, setDisableCloseButton] = useState((jobModel.creator_id == currentUser.id))
  const currentJobStatus = jobModel?.status ? jobModel.status : ''
  const [filterValue, setFilterValue] = useState([])
  const [wishlistExists, setWishlistExists] = useState(false)
  const [notesExists, setNotesExists] = useState(false)
  const [isSetSequenceId, setIsSetSequenceId] = useState((jobModel.creator_id == currentUser.id) ? true : false)
  const [inMailExists, setInMailExists] = useState(false)
  const [emailSequenceExists, setEmailSequenceExist] = useState(!emailSequenceExist)
  const [allowUpdateInMail, setAllowUpdateInMail] = useState(false)
  const [matchSkill, setMatchSkill] = useState(job.skills?.map((skill,index) => skill))
  const EMPTY_FILTERS = {
    titles: [],
    locations: [],
    skills: [],
    tags: [],
    company_names: [],
    top_company: false,
    schools: [],
    disciplines: [],
    degrees: [],
    top_school: false,
    active: false,
    phone_number_available: false,
    names: [],
    emails: [],
    email_type: '',
    keyword: '',
    withinPeriodKey: '',
    city: [],
    state: [],
    match_skill: false,
    skill_match: matchSkill
  }
  const [filterStack, setFilterStack] = useState(EMPTY_FILTERS)
  const [stageCount, setstageCount] = useState(job_stage_count)
  const [matchCount, setMatchCount] = useState(job_match_count)
  console.log(job_stage_count)
  useEffect(() => {
    feather.replace()
  })

  useEffect(()=>{
    if(isEmpty(job?.add_notes) && job?.upload_files?.length == 0){
      setNotesExists(true)
    }
    if(isEmpty(job.source_at)){
      setWishlistExists(true)
    }

    if(isEmpty(job?.in_mail_message)){
      setInMailExists(true)
    }
    if(isEmpty(job?.in_mail_message)){
      setInMailExists(true)
    }
    // if (job_owner.id == currentUser.id || job?.organization_manager_id == currentUser.id) {
    //   setAllowUpdateInMail(true);
    // }
    if (job_owner.id == currentUser.id || job?.organization_manager_id == currentUser.id) {
      setAllowUpdateInMail(true);
    }
    
  },[job])

  const ReadMore = () => {
    let length = 600;
    const text = job?.description;
    const text_length = job?.description?.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').length;
    if (text_length < length) {
      return <div dangerouslySetInnerHTML={{__html: text}}/>;
    }

    return (
      <>
        {isReadMore ? text?.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').slice(0, length) : <div dangerouslySetInnerHTML={{__html: text}} />}
        {text_length > length && <span onClick={() => setIsReadMore(!isReadMore)} className="read-or-hide" style={{color:'#4C68FF'}}>
          {isReadMore ? "...read more" : " show less"}
        </span>}
      </>
    );
  };


  // const ReadFile = () => {
  //   let length = 600;
  //   const text = job?.upload_files;
  //   console.log(text)
  //   const text_length = job?.upload_files?.length;
  //   console.log(text_length)
  //   if (text_length < length) {
  //     return  <li><a target='_blank' href={text}> <div dangerouslySetInnerHTML={{__html: text}}/></a> </li>;
  //   }else{
  //     return (<div>No notes added yet.</div>)
  //   }
  // };

  const ReadFile = () => {
    // const file = job?.upload_files;
    if(job?.upload_files?.length > 0)
      return (
        <div>
          <p><b>Attachments:</b></p>
          <ul>
            {[...job?.upload_files]?.map((file)=>{
                return (<li><a target='_blank' href={file?.url}>{file?.file_name}</a></li>)
            })}
            </ul>
        </div>
      )
  };

  const ReadNote = () => {
  let length = 600;
  const textarea = job?.add_notes;
    const textarea_length = job?.add_notes?.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').length;
    if (textarea_length < length) {
      return <div dangerouslySetInnerHTML={{__html: textarea}}/>;
    }
    return (
      <>
        {isReadMoreNotes ? <div dangerouslySetInnerHTML={{__html: textarea?.slice(0, length)}} /> : <div dangerouslySetInnerHTML={{__html: textarea}} />}
        {textarea_length > length && <span onClick={() => setIsReadMoreNotes(!isReadMoreNotes)} className="read-or-hide" style={{color:'#4C68FF'}}>
          {isReadMoreNotes ? "...read more" : " show less"}
        </span>}
      </>
    );
  };

    const fectchJob = async ()=>{
      const url =`jobs/${job.id}.json`

     
      const responce = await axios.get(url).then(res=>res).catch(error=>console.log(error))
      const responceJob = responce.data
      setJob(transformJobModel(responceJob))
    }

    const handleEditJob =()=>{
        setIsEditJob(true)
    }
    const handleWishList =()=>{
      setAddWishList(true)
    }

    const handleNoteList =()=>{
      setAddNoteList(true)
    }

    const handleEmailSequenceModalOpen = () => {
      setOpenEmailSequenceModal(true)
    }

    const handleInMailModalOpen  = () => {
      setOpenInMailModal(true)
    }

    const handleInterviewQuestionModalOpen  = () => {
      setOpenInterviewQuestionModal(true)
    }

    const handleDeleteFunction = () => {
      setOpenDeleteModal(true)
      setJobDelete(true)
    }

    const handleOpenExtendModal = (value) => {
      setOpenExtendModal(true)
      setJobExtend(true)
      setValue(value)
    }

    const handleSubmitJob = async (job)=>{
      let jobData = new FormData()
      const url = `/jobs/${jobModel.id}`
      const skillsString = job.mustHaveSkills.map((skill)=> skill.value).join(',')
      const techGroupString = job?.tech_groups?.map((tech_group)=> tech_group.value).join(',')
      const industryString = job?.industry?.map((ind)=> ind.value).join(',')
      jobData.append('job[name]', job.name)
      jobData.append('job[location]', job.location.label)
      jobData.append('job[description]', job.description)
      jobData.append('job[skills]', skillsString)
      jobData.append('job[nice_to_have_skills]', skillsString)
      jobData.append('job[department]', job.department.label)
      jobData.append('job[notificationemails]', job.notificationemails)
      jobData.append('job[linkedin_url]', job.linkedin_url)
      jobData.append('job[linkedin_url_2]', job.linkedin_url_2)
      jobData.append('job[linkedin_url_3]', job.linkedin_url_3)
      jobData.append('job[indeed_url]', job.indeed_url)
      jobData.append('job[tech_group]', techGroupString)
      jobData.append('job[industry]', industryString)
      jobData.append('job[create_lead]', job.create_lead.value)
      jobData.append('job[status]', job.status?.value)
      jobData.append('job[job_salary]', job.job_salary)
      //jobData.append('job[creator_id]', job.creator_id.value)
      if(job?.group_id){
        jobData.append('job[group_id]', job?.group_id)
      }
      jobData.append('job[linkedin_job_url]',job.linkedin_job_url)
      jobData.append('job[flag]', job.flag)

      const responce = await makeRequest(url, 'put', jobData,{
          contentType: 'application/json',
          loadingMessage: 'Submitting...',
          createResponseMessage: (responce) => {
              return {
                  message: responce?.message
                      ? responce.message
                      : 'Job Updated Successfully',
                  messageType: 'success',
                  loading: false,
                  autoClose: true,
              }
          },
      })
      fectchJob()
      setIsEditJob(!isEditJob)
    }


    const handleSubmitWishlist = async (jobWishList)=>{
      const url = `/jobs/${jobModel.id}`

      const responce = await makeRequest(url, 'put', jobWishList,{
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
        createResponseMessage: (responce) => {
            return {
                message: responce?.message
                    ? responce.message
                    : 'Sourcing Requirements added successfully',
                messageType: 'success',
                loading: false,
                autoClose: true,
            }
        },
        onSuccess: ()=>{
           window.localStorage.removeItem('job_source_id')
            setAddWishList(false)
            window.location.reload()
        }
    })

    fectchJob()
    setAddWishList(!addWishList)     
  }
  const handleSubmitNotelist = async (jobNotesList)=>{
    let jobData = new FormData()
    jobData.append('job[add_notes]', job.add_notes)
    jobData.append('job[upload_files]', job.upload_files)
    const url = `/jobs/${jobModel.id}`
    const responce = await makeRequest(url, 'put', jobNotesList,{
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (responce) => {
          return {
              message: responce?.message
                  ? responce.message
                  : 'Notes added successfully',
              messageType: 'success',
              loading: false,
              autoClose: true,
          }
      },
      onSuccess: ()=>{
        window.location.reload()
      }
  })
  fectchJob()
  setAddNoteList(!addNoteList)
}

const handleMixmaxJobAnaltic = () => {
  window.location.href = `/job/outreach/${jobModel?.id}`
}

    useEffect(() => {
      setTimeout(() => {
        setLoadData(true);
      }, 1000);
    },[loadData])
    return (
        <>
          {openDeleteModal &&
            <JobDeleteConfirmationModal
              setJobDelete={setJobDelete}
              jobDelete={jobDelete}
              job={job}
            />
          }
          {openExtendModal &&
            <JobExtendConfirmationModal
              setJobExtend={setJobExtend}
              jobExtend={jobExtend}
              job={job}
              noDayExtend={value}
            />
          }
            <Container className={styles.pageTop + ' p-0 mt-5'} fluid>
              <div className={styles.titleRow}>
                  <h1 className={styles.jobTitle}>{job.name}</h1>
                      <div className={styles.mainButtons}>
                      <Button
                          className={`${!jobStatus ? 'upload-button-disable' : notesExists ? 'function-button-danger' : 'function-button'}`}
                          onClick={handleNoteList}
                          disabled={!jobStatus}
                      >
                        Notes
                      </Button>
                      { (currentUser.agency_id == 0) &&
                        <>
                          <Button
                              className={`${!jobStatus ? 'upload-button-disable' : wishlistExists ? 'function-button-danger' : 'function-button'}`}
                              onClick={handleWishList}
                              disabled={!jobStatus}
                          >
                            Sourcing Requirements
                          </Button>
                          {/* <Button
                              className={`${!jobStatus ? 'upload-button-disable' : emailSequenceExists ? 'function-button-danger' : 'function-button'}`}
                              onClick={handleEmailSequenceModalOpen}
                              disabled={!jobStatus}
                          >
                              <img src={MultiEmailIcon} /> Email Sequence
                          </Button> */}
                          <Button
                              className={`${!jobStatus ? 'upload-button-disable' : inMailExists ? 'function-button-danger' : 'function-button'}`}
                              onClick={handleInMailModalOpen}
                              disabled={!jobStatus}
                          >
                              <img src={MultiEmailIcon} /> Auto Email Template
                          </Button>
                          <Button
                              className={`${!jobStatus ? 'upload-button-disable' : inMailExists ? 'function-button-danger' : 'function-button'}`}
                              onClick={handleInterviewQuestionModalOpen}
                              disabled={!jobStatus}
                          >
                             AI Interview Question
                          </Button>
                        </>
                      }
                      <Button
                          className={`${!jobStatus ? 'upload-button-disable' : 'function-button'}`}
                          onClick={handleEditJob}
                          disabled={!jobStatus}
                      >
                          <img src={EditIcon} /> Edit job
                      </Button>
                      <Button
                          onClick={handleDeleteFunction}
                          className={`${!jobStatus ? 'upload-button-disable' : 'function-button'}`}
                          disabled={disableCloseButton ? !jobStatus : true}
                      >
                          <img src={TrashIcon} /> Close job
                      </Button>

                         <Button
                         onClick={()=>setShowReferralModal(true)}
                         className={`${!jobStatus ? 'upload-button-disable' : 'function-button'}`}
                         disabled={!jobStatus}
                         >
                                <img src={PlusIcon} /> Invite to apply
                        </Button>

                      <Dropdown>
                          <Dropdown.Toggle
                              className={`${!jobStatus ? 'upload-button-disable' : 'upload-button'}`}
                              id="dropdown-basic"
                              disabled={!jobStatus}
                          >
                              <img src={UploadIcon} /> Upload candidates
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            align="right"
                            className="upload-candidate-button-menu"
                          >
                            <Dropdown.Item
                              onClick={() =>
                                setShowUploadCandidatePanel(
                                  (prevState) => !prevState
                                )
                              }
                            >
                            Add single candidate
                            </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  setIsBulkUploadOpen(!isBulkUploadOpen)
                                }
                              >
                            Bulk upload
                            </Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>

                      { ((expiryDate >= beforeExpiryDate && beforeExpiryDate <= currentDate && expiryDate >= currentDate) || (currentJobStatus === 'expired') ) &&
                        <Dropdown>
                            <Dropdown.Toggle
                                className={`${!jobStatus ? 'upload-button-disable' : 'upload-button'}`}
                                id="dropdown-basic"
                                disabled={!jobStatus}
                            >
                                <img src={PlusIcon} /> Extend job
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              align="right"
                              className="upload-candidate-button-menu"
                            >
                              <Dropdown.Item
                                onClick={() =>{
                                  handleOpenExtendModal(15)
                                }}
                              >
                              15 Days
                              </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>{
                                    handleOpenExtendModal(30)
                                  }}
                                >
                              30 Days
                              </Dropdown.Item>
                              <Dropdown.Item
                                  onClick={() =>{
                                    handleOpenExtendModal(45)
                                  }}
                                >
                              45 Days
                              </Dropdown.Item>
                              <Dropdown.Item
                                  onClick={() =>{
                                    handleOpenExtendModal(90)
                                  }}
                                >
                              90 Days
                              </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                      }

                      {/* { (isSetSequenceId &&  currentUser.agency_id == 0) &&
                        <Button
                          onClick={handleMixmaxJobAnaltic}
                          className={`${!jobStatus ? 'upload-button-disable' : 'function-button'}`}
                          disabled={disableCloseButton ? !jobStatus : true}
                        >
                          <i data-feather="codesandbox"/> Outreach Status
                        </Button>
                      } */}
                        <OverlayTrigger
                            overlay={popover(publicUrl)}
                            trigger="click"
                            placement="top"
                        >
                            <Button className={`${!jobStatus ? 'upload-button-disable' : 'function-button'}`} disabled={!jobStatus}>
                                <img src={ShareIcon} /> Share
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
                <Card className={styles.topWrapper}>
                    <Row>
                        <Col md="12">
                            <span className={styles.jobCompany}>{job.organization}</span><br></br>
                            <span className={styles.jobPosition}>{job.department || 'No department found'}</span><br></br>
                            <span className={styles.jobPosition}>{`${job_owner.first_name} ${job_owner.last_name}`}</span><br></br>
                            <span className={styles.jobLocation}>{job.location !== "null" ? job.location : 'No location found'}</span><br></br>
                        </Col>
                        <Col md="12" style={{marginTop: '5px'}}>
                          <Tabs
                            defaultActiveKey="skill-tab"
                            className="organization-activity-tabs"
                          >
                            <Tab eventKey="skill-tab" title="Skills">
                               <Col xs={12} sm={11}>
                               { job.skills && (
                                  <>
                                      <Col xs={12} sm={9} className="mt-3">
                                          <span className={styles.categorySpan}>
                                            Skills
                                          </span>
                                      </Col>
                                      <Col xs={12} sm={11} className="mt-3">
                                      {job.skills?.map((skill,index) => skillIsNotEmpty(skill) &&
                                          <Badge
                                                  pill
                                                  key={index}
                                                  variant="secondary"
                                                  className="skills-button d-inline-block text-truncate"
                                                  >
                                          <label className='candidate-skills'> {skill.toLowerCase()} </label>
                                          </Badge>
                                      )}
                                      </Col>
                                  </>
                                )}
                               </Col>
                            </Tab>
                            <Tab eventKey="about-tab" title="About">
                               <Col xs={12} sm={11}>
                                  <div className='description'>
                                    {ReadMore()}
                                  </div>
                               </Col>
                            </Tab>
                            <Tab eventKey="notes-tab" title="Notes">
                               <Col xs={12} sm={11} >
                                  <div className='addnote'>
                                     
                                    <label className='candidate-skills'>{ReadNote()} </label><br></br>
                                    <label className='candidate-skills'>{ReadFile()} </label>
                                  </div>
                               </Col>
                            </Tab>
                          </Tabs>              
                        </Col>
                       
                    </Row>
                </Card>
            </Container>
            { (alertApplyForJob || errorCreatingSubmission || loading) &&
              <Container>
                { alertApplyForJob && (
                            <Alert
                                variant="success"
                                onClose={() => setAlertApplyForJob(null)}
                                dismissible
                            >
                                {alertApplyForJob}
                            </Alert>
                        )}
                        {errorCreatingSubmission && (
                            <Alert
                                variant="danger"
                                onClose={() => setErrorCreatingSubmission(null)}
                                dismissible
                            >
                                {errorCreatingSubmission}
                            </Alert>
                        )}
                        {loading && (
                            <div className="d-flex justify-content-center">
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                )}
              </Container>
            }

              <Modal
                onHide={() => setIsEditJob(false)}
                className="edit-job-modal"
                show={isEditJob}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                backdrop="static"
              >
                 <JobModal 
                  job={job}
                  organizationId={currentOrganization.id}   
                  cardinalMember={ctRecruitersList} 
                  currentUser={currentUser}       
                  handleSubmitJob={handleSubmitJob}
                  onClose={()=>setIsEditJob(false)}
                  label="Edit Job"
                  members= {members}
                  jobGroup={job_groups}
                 />
              </Modal>

              <Modal
                onHide={() => (setAddWishList(false),window.localStorage.removeItem('job_source_id'))}
                className="wishlist-job-modal"
                show={addWishList}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                backdrop="static"
              >
                <JobWishlist
                 onClose={() => (setAddWishList(false),window.localStorage.removeItem('job_source_id'))}
                 handleSubmit={handleSubmitWishlist}
                 job={jobModel}
                 editMode={(is_source_job == job.id && jobModel.creator_id == currentUser.id) ? false :true}
                />
              </Modal>

              <Modal
                onHide={() => setAddNoteList(false)}
                className="wishlist-job-modal"
                show={addNoteList}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                backdrop="static"
              >
                <JobNotes 
                job={jobModel}
                onClose={() => setAddNoteList(false)}
                handleSubmit={handleSubmitNotelist}
                allowUpdate={allowUpdateInMail}
                />
              </Modal>
              

           {openEmailSequenceModal &&
             <Container>
                <Modal
                    className="custom-modal"
                    show={openEmailSequenceModal}
                    onHide={()=>setOpenEmailSequenceModal(false)}
                    size={'xl'}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered
                >
                  <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Email Sequence
                        </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <EmailSequence
                      openEmailSequenceModal={openEmailSequenceModal}
                      job={job}
                      setOpenEmailSequenceModal={setOpenEmailSequenceModal}
                      allowUpdateEmailSequence={allowUpdateInMail}
                    />
                  </Modal.Body>
                </Modal>
              </Container>}

              {openInMailModal &&
                <Modal
                  onHide={() => setOpenInMailModal(false)}
                  className="custom-modal"
                  show={openInMailModal}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  scrollable
                  backdrop="static"
                >
                  <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                          Auto Email Template
                        </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <InMailModal 
                    job={job}
                    allowUpdate={allowUpdateInMail}
                    onClose={() => setOpenInMailModal(false)}
                  />
                  </Modal.Body>
                </Modal>
              }
              
              {openInterviewQuestionModal &&
                <Modal
                  onHide={() => setOpenInterviewQuestionModal(false)}
                  className="custom-modal"
                  show={openInterviewQuestionModal}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  scrollable
                  backdrop="static"
                >
                  <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                          AI Interview Questions
                        </Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{minHeight: '540px'}}>
                  <InterviewQuestionModal 
                    job={job}
                    allowUpdate={allowUpdateInMail}
                    onClose={() => setOpenInterviewQuestionModal(false)}
                  />
                  </Modal.Body>
                </Modal>
              }
        <Container
          style={{ position: 'relative', paddingTop: '25px' }}
          className={`${styles.pageBottom + ' p-0'}`}
          fluid
        >
          {
            <div className="p-0">
              <Modal
                className=""
                show={isBulkUploadOpen}
                onHide={() =>
                    setIsBulkUploadOpen(!isBulkUploadOpen)}
                onBackdropClick={!isBulkUploadOpen}
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                scrollable
                centered
                backdrop="static"
              >
              <Modal.Body className={styles.modalBody}>
                <div className={styles.contentContainer}>
                <button onClick={() =>
                                  setIsBulkUploadOpen(false)
                                }type="button" class="close">×</button>
                  <BulkCandidateUpload
                      ATSBaseUrl={ATSBaseUrl}
                      CSVSampleURL={CSVSampleURL}
                      jobId={job.id}
                      hideHeaderText={true}
                      userID={currentUser.id}
                      organizationID={currentUser.organization_id}
                      modalClose={() => {setIsBulkUploadOpen(false)}}
                  />
                  <Row>
                    <Col lg={4} xs={12}>
                      <div className={styles.contentText} style={{'float': 'left'}}>
                        <a href='https://cardinaltalent-prod.s3.us-east-2.amazonaws.com/Bulk+candidates+upload+sample.xlsx'>Download Sample excel file</a>
                      </div>
                    </Col>

                    <Col lg={8} xs={12}>
                      <div className={styles.contentText}  style={{'float': 'right'}}>
                          These candidates will be added to Leads for this job
                      </div>
                    </Col>
                  </Row>
                </div>
              </Modal.Body>
              </Modal>
                <Tabs
                  className="job-detail-tabs"
                  defaultActiveKey="applicant"
                  activeKey={activeTab}
                  onSelect={(t) => {
                    localStorage.setItem('Stage', t)
                    setActiveTab(t)
                  }}
                >
              { (currentUser.agency_id == 0) &&
                <Tab eventKey={LEADS}
                     title={
                            <React.Fragment>
                              Leads
                              {stageCount?.leads != 0 && (
                              <Badge className={styles.stageCount} variant='light'>{stageCount?.leads}</Badge>
                              )}
                            </React.Fragment>
                          }
                >
                  {activeTab === LEADS && (
                    <CandidateManager
                      tableColumns={getTableColumnsForTab(
                          LEADS
                      )}
                      memberOrganization={memberOrganization}
                      currentOrganization={currentOrganization}
                      isEmailConfigured={isEmailConfigured}
                      user={currentUser}
                      stage={activeTab}
                      candidateSource="submitted_candidates"
                      placeholder='Search by first name, last name, skills'
                      jobId={job.id}
                      fullJob={job}
                      title={'Leads for ' + job.name}
                      showSearchField={false}
                      showUploadCandidatePanel={
                          showUploadCandidatePanel
                      }
                      setShowUploadCandidatePanel={
                          setShowUploadCandidatePanel
                      }

                      allowCandidateUpload
                      showdisplaycount={showdisplaycount}
                      filterStack={filterStack}
                      setFilterStack={setFilterStack}
                      emptyFilter={EMPTY_FILTERS}
                      setFilterValue={setFilterValue}
                      filterValue={filterValue}
                      ctRecruitersList = {ctRecruitersList}
                      showCandidateModal = {showCandidateModal}
                      setstageCount={setstageCount}
                      setMatchCount={setMatchCount}  
                    />
                  )}
                </Tab>
               }
               { memberOrganization && currentUser.is_commission_recruiter != 'yes' && 
                  <Tab eventKey={ACTIVE_CANDIDATES}
                      title={
                              <React.Fragment>
                                Active Candidates
                                { stageCount?.active_candidates != 0 &&
                                  <Badge className={styles.stageCount} variant='light'>{ ((stageCount?.active_candidates - stageCount?.archived) < 0) ? 0 : stageCount?.active_candidates - stageCount?.archived }</Badge>
                                }
                              
                              </React.Fragment>
                            }
                  >
                    {activeTab === ACTIVE_CANDIDATES && (
                      <CandidateManager
                        tableColumns={getTableColumnsForTab(
                          ACTIVE_CANDIDATES
                        )}
                        memberOrganization={memberOrganization}
                        currentOrganization={currentOrganization}
                        isEmailConfigured={isEmailConfigured}
                        user={currentUser}
                        stage={activeTab}
                        candidateSource="submitted_candidates"
                        jobId={job.id}
                        fullJob={job}
                        title={'Active Candidates for ' + job.name}
                        showUploadCandidatePanel={
                            showUploadCandidatePanel
                        }
                        setShowUploadCandidatePanel={
                            setShowUploadCandidatePanel
                        }
                        allowCandidateUpload
                        showdisplaycount={showdisplaycount}
                        filterStack={filterStack}
                        setFilterStack={setFilterStack}
                        emptyFilter={EMPTY_FILTERS}
                        setFilterValue={setFilterValue}
                        filterValue={filterValue}
                        ctRecruitersList = {ctRecruitersList}
                        showCandidateModal = {showCandidateModal}
                        setstageCount={setstageCount}
                        setMatchCount={setMatchCount}  
                      />
                    )}
                  </Tab>
                }
                { is_show_applicant == true &&
                <Tab eventKey={APPLICANTS}
                    title={
                      <React.Fragment>
                       Applicants
                       {stageCount?.applicant != 0 && (
                        <Badge className={styles.stageCount} variant='light'>{stageCount?.applicant}</Badge>
                       )}
                      </React.Fragment>
                    }
                >
                  {activeTab === APPLICANTS && (
                    <CandidateManager
                      tableColumns={getTableColumnsForTab(
                        APPLICANTS,
                        showGradingUI
                      )}
                      memberOrganization={memberOrganization}
                      currentOrganization={currentOrganization}
                      isEmailConfigured={isEmailConfigured}
                      user={currentUser}
                      stage={activeTab}
                      candidateSource="submitted_candidates"
                      jobId={job.id}
                      fullJob={job}
                      title={'Applicants for ' + job.name}
                      showUploadCandidatePanel={
                          showUploadCandidatePanel
                      }
                      setShowUploadCandidatePanel={
                          setShowUploadCandidatePanel
                      }
                      allowCandidateUpload
                      showdisplaycount={showdisplaycount}
                      filterStack={filterStack}
                      setFilterStack={setFilterStack}
                      emptyFilter={EMPTY_FILTERS}
                      setFilterValue={setFilterValue}
                      filterValue={filterValue}
                      ctRecruitersList = {ctRecruitersList}
                      showCandidateModal = {showCandidateModal}
                      setstageCount={setstageCount}
                      setMatchCount={setMatchCount}  
                    />
                  )}
                </Tab>
                }
                <Tab eventKey={AI_MATCHED}
                    title={
                      <React.Fragment>
                       AI Matched
                       {matchCount != 0 && (
                        <Badge className={styles.stageCount} variant='light'>{matchCount}</Badge>
                       )}
                      </React.Fragment>
                    }
                >
                  {activeTab === AI_MATCHED && (
                    <CandidateManager
                      tableColumns={getTableColumnsForTab(
                        AI_MATCHED,
                        showGradingUI
                      )}
                      memberOrganization={memberOrganization}
                      currentOrganization={currentOrganization}
                      isEmailConfigured={isEmailConfigured}
                      user={currentUser}
                      stage={activeTab}
                      candidateSource="submitted_candidates"
                      jobId={job.id}
                      fullJob={job}
                      title={'AI Matched Candidates for ' + job.name}
                      showUploadCandidatePanel={
                          showUploadCandidatePanel
                      }
                      setShowUploadCandidatePanel={
                          setShowUploadCandidatePanel
                      }
                      allowCandidateUpload
                      showdisplaycount={showdisplaycount}
                      filterStack={filterStack}
                      setFilterStack={setFilterStack}
                      emptyFilter={EMPTY_FILTERS}
                      setFilterValue={setFilterValue}
                      filterValue={filterValue}
                      ctRecruitersList = {ctRecruitersList}
                      showCandidateModal = {showCandidateModal}
                      setstageCount={setstageCount}
                      setMatchCount={setMatchCount}  
                    />
                  )}
                </Tab>

                <Tab
                  eventKey={RECRUITOR_SCREEN}
                  title={
                    <React.Fragment>
                      Recruiter Screened
                      {stageCount?.recruiter != 0 && (
                      <Badge className={styles.stageCount} variant='light'>{stageCount?.recruiter}</Badge>
                      )}
                    </React.Fragment>
                  }
                >
                  {activeTab === RECRUITOR_SCREEN && (
                    <CandidateManager
                      tableColumns={getTableColumnsForTab(
                          RECRUITOR_SCREEN
                      )}
                      memberOrganization={memberOrganization}
                      currentOrganization={currentOrganization}
                      isEmailConfigured={isEmailConfigured}
                      user={currentUser}
                      stage={activeTab}
                      candidateSource="submitted_candidates"
                      jobId={job.id}
                      fullJob={job}
                      title={
                          'Recruiter screened for ' + job.name
                      }
                      showUploadCandidatePanel={
                          showUploadCandidatePanel
                      }
                      setShowUploadCandidatePanel={
                          setShowUploadCandidatePanel
                      }
                      allowCandidateUpload
                      showdisplaycount={showdisplaycount}
                      filterStack={filterStack}
                      setFilterStack={setFilterStack}
                      emptyFilter={EMPTY_FILTERS}
                      setFilterValue={setFilterValue}
                      filterValue={filterValue}
                      ctRecruitersList = {ctRecruitersList}
                      showCandidateModal = {showCandidateModal}
                      setstageCount={setstageCount}
                      setMatchCount={setMatchCount}  
                    />
                  )}
                </Tab>

                <Tab
                    eventKey={SUBMITTED}
                    title={
                      <React.Fragment>
                        Submitted
                      {stageCount?.submitted != 0 && (
                        <Badge className={styles.stageCount} variant='light'>{stageCount?.submitted}</Badge>
                      )}
                      </React.Fragment>
                    }
                >
                  {activeTab === SUBMITTED && (
                    <CandidateManager
                      tableColumns={getTableColumnsForTab(
                          SUBMITTED
                      )}
                      isEmailConfigured={isEmailConfigured}
                      user={currentUser}
                      stage={activeTab}
                      memberOrganization={memberOrganization}
                      currentOrganization={currentOrganization}
                      candidateSource="submitted_candidates"
                      jobId={job.id}
                      fullJob={job}
                      title={
                          'Submitted interviews for ' + job.name
                      }
                      showUploadCandidatePanel={
                          showUploadCandidatePanel
                      }
                      setShowUploadCandidatePanel={
                          setShowUploadCandidatePanel
                      }
                      allowCandidateUpload
                      showdisplaycount={showdisplaycount}
                      filterStack={filterStack}
                      setFilterStack={setFilterStack}
                      emptyFilter={EMPTY_FILTERS}
                      setFilterValue={setFilterValue}
                      filterValue={filterValue}
                      ctRecruitersList = {ctRecruitersList}
                      showCandidateModal = {showCandidateModal}
                      setstageCount={setstageCount}
                      setMatchCount={setMatchCount}  
                    />
                  )}
                </Tab>

                <Tab
                    eventKey={FIRST_INTERVIEWS}
                    title={
                      <React.Fragment>
                        First Interviews
                      {stageCount?.first_interview != 0 && (
                        <Badge className={styles.stageCount} variant='light'>{stageCount?.first_interview}</Badge>
                      )}
                      </React.Fragment>
                    }
                >
                  {activeTab === FIRST_INTERVIEWS && (
                    <CandidateManager
                      tableColumns={getTableColumnsForTab(
                          FIRST_INTERVIEWS
                      )}
                      memberOrganization={memberOrganization}
                      currentOrganization={currentOrganization}
                      isEmailConfigured={isEmailConfigured}
                      user={currentUser}
                      stage={activeTab}
                      candidateSource="submitted_candidates"
                      jobId={job.id}
                      fullJob={job}
                      title={
                          'First interviews for ' + job.name
                      }
                      showUploadCandidatePanel={
                          showUploadCandidatePanel
                      }
                      setShowUploadCandidatePanel={
                          setShowUploadCandidatePanel
                      }
                      allowCandidateUpload
                      showdisplaycount={showdisplaycount}
                      filterStack={filterStack}
                      setFilterStack={setFilterStack}
                      emptyFilter={EMPTY_FILTERS}
                      setFilterValue={setFilterValue}
                      filterValue={filterValue}
                      ctRecruitersList = {ctRecruitersList}
                      showCandidateModal = {showCandidateModal}
                      setstageCount={setstageCount}
                      setMatchCount={setMatchCount}  
                    />
                  )}
                </Tab>

                <Tab
                  eventKey={SECOND_INTERVIEWS}
                  title={
                    <React.Fragment>
                      Second Interviews
                      {stageCount?.second_interview != 0 && (
                      <Badge className={styles.stageCount} variant='light'>{stageCount?.second_interview}</Badge>
                      )}
                    </React.Fragment>
                  }
                >
                  {activeTab === SECOND_INTERVIEWS && (
                    <CandidateManager
                      tableColumns={getTableColumnsForTab(
                          SECOND_INTERVIEWS
                      )}
                      memberOrganization={memberOrganization}
                      currentOrganization={currentOrganization}
                      isEmailConfigured={isEmailConfigured}
                      user={currentUser}
                      stage={activeTab}
                      candidateSource="submitted_candidates"
                      jobId={job.id}
                      fullJob={job}
                      title={
                          'Second Interviews for ' + job.name
                      }
                      showUploadCandidatePanel={
                          showUploadCandidatePanel
                      }
                      setShowUploadCandidatePanel={
                          setShowUploadCandidatePanel
                      }
                      allowCandidateUpload
                      showdisplaycount={showdisplaycount}
                      filterStack={filterStack}
                      setFilterStack={setFilterStack}
                      emptyFilter={EMPTY_FILTERS}
                      setFilterValue={setFilterValue}
                      filterValue={filterValue}
                      ctRecruitersList = {ctRecruitersList}
                      showCandidateModal = {showCandidateModal}
                      setstageCount={setstageCount}
                      setMatchCount={setMatchCount}  
                    />
                  )}
                </Tab>
                <Tab
                    eventKey={ON_HOLD}
                    title={
                      <React.Fragment>
                        On Hold
                      {stageCount?.onhold != 0 && (
                        <Badge className={styles.stageCount} variant='light'>{stageCount?.onhold}</Badge>
                      )}
                      </React.Fragment>
                    }
                    >
                  {activeTab === ON_HOLD && (
                    <CandidateManager
                      tableColumns={getTableColumnsForTab(
                          ON_HOLD
                      )}
                      memberOrganization={memberOrganization}
                      currentOrganization={currentOrganization}
                      isEmailConfigured={isEmailConfigured}
                      user={currentUser}
                      stage={activeTab}
                      candidateSource="submitted_candidates"
                      jobId={job.id}
                      fullJob={job}
                      title={
                          'On Hold for ' + job.name
                      }
                      showUploadCandidatePanel={
                          showUploadCandidatePanel
                      }
                      setShowUploadCandidatePanel={
                          setShowUploadCandidatePanel
                      }
                      allowCandidateUpload
                      showdisplaycount={showdisplaycount}
                      filterStack={filterStack}
                      setFilterStack={setFilterStack}
                      emptyFilter={EMPTY_FILTERS}
                      setFilterValue={setFilterValue}
                      filterValue={filterValue}
                      ctRecruitersList = {ctRecruitersList}
                      showCandidateModal = {showCandidateModal}
                      setstageCount={setstageCount}
                      setMatchCount={setMatchCount}  
                    />
                  )}
                </Tab>
                <Tab eventKey={OFFERS}
                     title={
                      <React.Fragment>
                        Offers
                        {stageCount?.offer != 0 && (
                        <Badge className={styles.stageCount} variant='light'>{stageCount?.offer}</Badge>
                        )}
                      </React.Fragment>
                    }
                >
                  {activeTab === OFFERS && (
                    <CandidateManager
                      tableColumns={getTableColumnsForTab(
                          OFFERS
                      )}
                      memberOrganization={memberOrganization}
                      currentOrganization={currentOrganization}
                      isEmailConfigured={isEmailConfigured}
                      user={currentUser}
                      stage={activeTab}
                      candidateSource="submitted_candidates"
                      jobId={job.id}
                      fullJob={job}
                      title={'Offers for ' + job.name}
                      showUploadCandidatePanel={
                          showUploadCandidatePanel
                      }
                      setShowUploadCandidatePanel={
                          setShowUploadCandidatePanel
                      }
                      allowCandidateUpload
                      showdisplaycount={showdisplaycount}
                      filterStack={filterStack}
                      setFilterStack={setFilterStack}
                      emptyFilter={EMPTY_FILTERS}
                      setFilterValue={setFilterValue}
                      filterValue={filterValue}
                      ctRecruitersList = {ctRecruitersList}
                      showCandidateModal = {showCandidateModal}
                      setstageCount={setstageCount}
                      setMatchCount={setMatchCount}  
                    />
                  )}
                </Tab>

                 <Tab eventKey={HIRED}
                     title={
                      <React.Fragment>
                        Hired
                        {stageCount?.hired != 0 && (
                        <Badge className={styles.stageCount} variant='light'>{stageCount?.hired}</Badge>
                        )}
                      </React.Fragment>
                    }
                >
                  {activeTab === HIRED && (
                    <CandidateManager
                      tableColumns={getTableColumnsForTab(
                          HIRED
                      )}
                      memberOrganization={memberOrganization}
                      currentOrganization={currentOrganization}
                      isEmailConfigured={isEmailConfigured}
                      user={currentUser}
                      stage={activeTab}
                      candidateSource="submitted_candidates"
                      jobId={job.id}
                      fullJob={job}
                      title={'Hired for ' + job.name}
                      showUploadCandidatePanel={
                          showUploadCandidatePanel
                      }
                      setShowUploadCandidatePanel={
                          setShowUploadCandidatePanel
                      }
                      allowCandidateUpload
                      showdisplaycount={showdisplaycount}
                      filterStack={filterStack}
                      setFilterStack={setFilterStack}
                      emptyFilter={EMPTY_FILTERS}
                      setFilterValue={setFilterValue}
                      filterValue={filterValue}
                      ctRecruitersList = {ctRecruitersList}
                      showCandidateModal = {showCandidateModal}
                      setstageCount={setstageCount}
                      setMatchCount={setMatchCount}  
                    />
                  )}
                </Tab>

                <Tab eventKey={ARCHIVED}
                     title={
                      <React.Fragment>
                        Archived
                        {stageCount?.archived != 0 && (
                        <Badge className={styles.stageCount} >{stageCount?.archived}</Badge>
                        )}
                      </React.Fragment>
                    }
                >
                  {activeTab === ARCHIVED && (
                    <CandidateManager
                      tableColumns={getTableColumnsForTab(
                          ARCHIVED
                      )}
                      memberOrganization={memberOrganization}
                      currentOrganization={currentOrganization}
                      isEmailConfigured={isEmailConfigured}
                      user={currentUser}
                      stage={activeTab}
                      candidateSource="submitted_candidates"
                      jobId={job.id}
                      fullJob={job}
                      title={'Archived for ' + job.name}
                      showUploadCandidatePanel={
                          showUploadCandidatePanel
                      }
                      setShowUploadCandidatePanel={
                          setShowUploadCandidatePanel
                      }
                      allowCandidateUpload
                      showdisplaycount={showdisplaycount}
                      filterStack={filterStack}
                      setFilterStack={setFilterStack}
                      emptyFilter={EMPTY_FILTERS}
                      setFilterValue={setFilterValue}
                      filterValue={filterValue}
                      ctRecruitersList = {ctRecruitersList}
                      showCandidateModal = {showCandidateModal}
                      setstageCount={setstageCount}
                      setMatchCount={setMatchCount}  
                    />
                  )}
                </Tab>
            </Tabs>
        </div>
    }

                <JobDescriptionModal
                    job={job}
                    show={showJobDescriptionModal}
                    onHide={() => setShowJobDescriptionModal(false)}
                />
               { showReferralModal &&
                   <ReferralModal
                      job={job}
                      show={showReferralModal}
                      onHide={() =>{
                        setShowReferralModal(false)}
                      } 
                      onShow={() => setShowReferralModal(true)}
                      setErrorCreatingSubmission={(data) => {
                        setErrorCreatingSubmission(data)
                       }}
                      setAlertApplyForJob={(data) => {
                       setAlertApplyForJob(data)
                       }}
                    />
                }

            {showRefinmentPage && (
              <RefinementPage
                  job={job}
                  setJob={setJob}
                  transformJobModel={transformJobModel}
                  closeFunc={() => setShowRefinementpage(false)}
              />
            )}
        </Container>
      </>
    )
}

/*
 * Customize ruby model object for use on front-end
 */
function transformJobModel(model) {

    let {
        id,
        user_id,
        portalcompanyname,
        company_preference,
        education_preference,
        experience_years,
        prefered_industries,
        prefered_titles,
        work_time,
        location,
        name,
        description,
        gen_reqs,
        skills,
        pref_skills,
        benefits,
        niceToHaveSkills,
        department,
        referral_candidate,
        location_preference,
        organization,
        organization_manager_id,
        keywords,
        notificationemails,
        nice_to_have_keywords,
        linkedin_url,
        linkedin_url_2,
        linkedin_url_3,
        indeed_url,
        sourcer_notes,
        work_authorization,
        compensation,
        employment_type,
        source_at,
        group_id,
        boolean_keyword_search,
        upload_files,
        add_notes,
        linkedin_job_url,
        manual_sourcing,
        status,
        tech_group,
        in_mail_message,
        create_lead,
        creator_id,
        job_detail_file,
        job_salary,
        industry,
        prefered_top_schools
    } = model

    const company = portalcompanyname
    const workingTime = work_time
    compensation =
      !isEmpty(compensation) && compensation.charAt(0) !== '$'
        ? compensation
        : compensation
    const genReqs = gen_reqs?.split(',') ?? []
    skills = skills?.split(',') ?? []
    const prefSkills = pref_skills?.split(',') ?? []
    niceToHaveSkills = niceToHaveSkills?.split(',') ?? []
    benefits = benefits?.split(',') ?? []

    return {
        id,
        user_id,
        compensation,
        location,
        workingTime,
        company,
        name,
        description,
        genReqs,
        skills,
        prefSkills,
        benefits,
        niceToHaveSkills,
        department,
        referral_candidate,
        location_preference,
        organization, 
        organization_manager_id,
        notificationemails,
        company_preference,
        education_preference,
        experience_years,
        prefered_industries,
        prefered_titles,
        keywords,
        nice_to_have_keywords,
        linkedin_url,
        linkedin_url_2,
        linkedin_url_3,
        indeed_url,
        work_authorization,
        sourcer_notes,
        employment_type,
        source_at,
        group_id,
        boolean_keyword_search,
        upload_files,
        add_notes,
        linkedin_job_url,
        manual_sourcing,
        status,
        tech_group,
        in_mail_message,
        create_lead,
        creator_id,
        job_detail_file,
        job_salary,
        industry,
        prefered_top_schools
    }}

/*
 * Contains the logic that determines which table columns
 * are shown on each of the different tabs.
 */
function getTableColumnsForTab(tabName, showGradingUI = false) {
  const defaultTableColumns = [
    'select',
    'image',
    'candidate',
    'skills',
    // 'last-contacted',
    // 'message',
  ]

  if (tabName === ACTIVE_CANDIDATES) {
    defaultTableColumns.push('submissions_detail')
  }

  if (tabName !== ACTIVE_CANDIDATES) {
    if (tabName !== LEADS){
      defaultTableColumns.push('moved_from')  
    }
    defaultTableColumns.push('applied')
    defaultTableColumns.push('moved_by')
  }
  if (tabName == AI_MATCHED){
    defaultTableColumns.push('match')
  }
  
  // if (tabName === LEADS || tabName === ARCHIVED) {
  //   // delete 'applied'
  //   const copy = defaultTableColumns.slice()
  //   copy.splice(4, 1)
  //   return copy
  // }
  if (tabName === APPLICANTS && showGradingUI) {
    // insert 'grading'
    const copy = defaultTableColumns.slice()
    copy.splice(2, 0, 'grading')
    return copy
  }
  defaultTableColumns.push('history')

  return defaultTableColumns
}

export default JobDetailPage